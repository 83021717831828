import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import queryString from 'query-string';

import logo from '../../assets/allWesternCanada.png';
import skLogo from '../../assets/sk_headerlogo.png';
import abLogo from '../../assets/ab_headerlogo.png';

import appStoreBadge from '../../assets/US/Download_on_App_Store/Black_lockup/SVG/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import playStoreBadge from '../../assets/google-play-badge.png';

import './styles.css';

const APPLE_URL = 'https://apps.apple.com/us/app/allsaskatchewan/id1591958633';
const GOOGLE_URL = 'https://play.google.com/store/apps/details?id=com.allsaskatchewan.app';

const alert = withReactContent(Swal);

const alertText =
  <p>
    <b>allWestern</b> is now exclusively
    available for mobile devices on the appropriate app store.
  </p>;

export default class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        notice: undefined,
        redirect: undefined,
      },
    };
  }

  getQuery() {
    const query = this.props.location.search;
    const params = query ? queryString.parse(query) : {};
    this.setState({params});
  }

  fireNotice() {
    alert.fire({
      type: 'info',
      html: alertText
    });
  }

  _showSubBrand() {
    return (
      <Row className='smaller_logo_container'>
        <Col lg={6} md={4} sm={12}>
          <a href='https://allsaskatchewan.com/'>
            <img className='sk_logo' src={skLogo} alt='skLogo'/>
          </a>
        </Col>

        <Col lg={4} md={6} sm={12} className='ab_logo_container'>
          <a href='https://allalberta.com/'>
            <img className='ab_logo' src={abLogo} alt='abLogo'/>
          </a>
        </Col>
      </Row>
    );
  }

  _downloadApp() {
    return (
      <Row className='justify-content-center'>
        <Col lg={5} md={7} sm={10} className='download_text_container'>
          <p>
            allWestern subscribers have been
            directed to this website to download our
            new app. Please follow the link below for a better mobile
            experience.
          </p>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Container>
        <Row  className='logo_container'>
            <img  src={logo} className='logo' alt='allWestern-logo'/>
        </Row>

        {this.state.params.redirect ? this._downloadApp() :
          this._showSubBrand()}

        <Row className='badges align-items-end'>
          <Col lg={5} md={5} sm={5} className='apple_badge'>
            <a href={APPLE_URL}>
              <img src={appStoreBadge} className='store_badge'
                alt='appStore-badge'/>
            </a>
          </Col>
          <Col className='spacer'/>
          <Col lg={5} md={5} sm={5} className='google_badge'>
            <a href={GOOGLE_URL}>
              <img src={playStoreBadge} className='store_badge'
                alt='appStore-badge'/>
            </a>
          </Col>
        </Row>
      </Container>
    );
  }

  componentDidMount() {
    this.getQuery();
  }

  componentDidUpdate() {
    if (this.state.params && this.state.params.notice) this.fireNotice();
  }
}